<template>
  
    <div>

      <Production :post="post" />

      <Facebook :post="post" />

      <LinkedIn :post="post" />

    </div>

</template>

<style>

</style>

<script lang="ts">
import { Ref, defineComponent, PropType, computed, onMounted, onBeforeUnmount, ref, watch, reactive } from '@fwk-node-modules/vue'

import Facebook from './publish/Facebook.vue';
import LinkedIn from './publish/LinkedIn.vue';
import Production from './publish/Production.vue';

export default defineComponent({
  props: {
      post: {
        type: Object as PropType<any>,
        required: false
      }
  },
  components: {
    Facebook,
    LinkedIn,
    Production
  },
  setup(props, context) {
  }
})
</script>