<template>
  
    <form role="form" @submit="onFormSubmit" name="publish-post">

      <h4>{{$t('blog.post.publish.production.title')}}</h4>
      <ul>
        <li>{{$t('blog.post.info.title')}}: {{ getLocalizedText(post.title) }}</li>
        <li>{{$t('blog.post.info.picture')}}: {{ (post.picture && post.picture.original) ? post.picture.original.path : "-" }}</li>
        <li>{{$t('blog.post.info.pictures')}}: {{ post.content.pictures ? post.content.pictures.length : 0 }}</li>
        <li>{{$t('blog.post.info.lastUpdateDateTime')}}: {{ formatDateTime(post.lastUpdateDateTime) }}</li>
      </ul>


      <div class="production-post">
        <h4>{{$t('blog.post.publish.production.production-post')}}</h4>
        <ul v-if="productionPost">
          <li>{{$t('blog.post.info.title')}}: {{ getLocalizedText(productionPost.title) }}</li>
          <li>{{$t('blog.post.info.picture')}}: {{ (productionPost.picture && productionPost.picture.original) ? productionPost.picture.original.path : "-" }}</li>
          <li>{{$t('blog.post.info.pictures')}}: {{ productionPost.content.pictures ? productionPost.content.pictures.length : 0 }}</li>
          <li>{{$t('blog.post.info.lastUpdateDateTime')}}: {{ formatDateTime(productionPost.lastUpdateDateTime) }}</li>
        </ul>
        <span v-if="productionPost == null">{{$t('blog.post.publish.production.no-production-post')}}</span>
      </div>
      
      <button class="btn btn-primary ladda-button publish-post" data-style="zoom-in" type="submit">{{$t('blog.post.publish.production.button')}}</button>
    </form>

</template>

<style scoped>
  .fa-check.active, .fa-user-circle-o.active {
    color:green;
  }
  .fa-check.disabled, .fa-user-circle-o.disabled {
    color:red;
  }

  
</style>

<script lang="ts">
import { toRefs, Ref, ref, defineComponent, PropType, computed, onMounted } from '@fwk-node-modules/vue'
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';
import * as api from '@fwk-client/utils/api';
import * as Ladda from 'ladda';
import { languagesTypes } from '@fwk-client/store/types';
import { formatDate } from '@igotweb-node-api-utils/formatter';

import { usePostAdmin } from '../../../../composables/usePostAdmin';

export default defineComponent({
  props: {
    post: Object as PropType<any>
  },
  components: {
      
  },
  setup(props, context) {
      const app = getApp();
      const $router = useRouter();
      const $store = useStore();

      const { getProductionPostDetails, publishPostProduction } = usePostAdmin(props, context);

      const productionPost:Ref<any> = ref(undefined);

      const { post } = toRefs(props);

      var laddaSubmit:Ladda.LaddaButton|null = null;

      onMounted(() => {
        var publishButton:HTMLButtonElement|null = document.querySelector( 'form[name=publish-post] button.ladda-button.publish-post' );
        laddaSubmit = Ladda.create(publishButton!);
      })

      var isProductionPostLoading:boolean = false;
      const getProductionPost = () => {
        isProductionPostLoading = true;
        getProductionPostDetails(post.value._id).then((post:any) => {
          if(post) {  
            productionPost.value = post;
          }
          else {
            // There is no production post
            productionPost.value = null;
          }

          isProductionPostLoading = false;
        });
      }
      // We load the production product.
      getProductionPost();

      const onFormSubmit = (evt:Event) => {
        evt.preventDefault();

        laddaSubmit!.start();

        return publishPostProduction(post.value._id).then((response:any) => {
          laddaSubmit!.stop();
          if(response.post) {  
            productionPost.value = response.post;
          }
        })
      }

    const currentLanguageCode = $store.getters['languages/' + languagesTypes.getters.GET_CURRENT_LANGUAGE];
    
    const formatDateTime = (date:Date) => {
      return formatDate(date, currentLanguageCode);
    }

    return {
      formatDateTime,
      isProductionPostLoading,
      post,
      productionPost,
      onFormSubmit
    }
  }
})
</script>