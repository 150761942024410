<template>
  
    <form v-if="facebookPosts.length > 0 || listEligiblePages.length > 0" role="form" @submit="onFormSubmit" name="publish-post-facebook" class="publish-post-facebook">
      <h2>Facebook</h2>  
      <div class="published" v-if="facebookPosts.length > 0">
        Published on pages:
        <ul>
          <li v-for="(facebookPost,index) of facebookPosts" :key="'facebookPost-'+index+JSON.stringify(facebookPostDetails)">
            {{ getPageNameFromPost(facebookPost) }} - <a :href="'https://www.facebook.com/'+facebookPost.id" target="_blank" >Afficher</a>
            <div v-if="facebookPostDetails[facebookPost.id]">
              Published: {{ facebookPostDetails[facebookPost.id].is_published ? 'Yes' : 'No' }}
              <button v-if="!facebookPostDetails[facebookPost.id].is_published" @click="publishFacebookLive(facebookPost)" class="btn btn-primary publish-facebook" type="button">{{$t('blog.post.publish.facebook.publish-button')}}</button>
            </div>
            <button v-if="!facebookPostDetails[facebookPost.id].is_published" @click="removeFromFacebook(facebookPost)" class="btn btn-primary remove-facebook" type="button">{{$t('blog.post.publish.facebook.remove-button')}}</button>
          </li>
        </ul>
      </div>

      <div class="publish" v-if="listEligiblePages.length > 0">

        <Select 
          :selectOptions="{
            options: listEligiblePages,
            getOptionLabel : option => option.name,
            reduce : page => page.id
          }"
          v-bind:value.sync="form.pageID" 
          id="targetPage" 
          label="Facebook Page" 
          placeholder="Select a Facebook Page" 
          required 
          labelAsColumn 
        />

        <button class="btn btn-primary ladda-button publish" data-style="zoom-in" type="submit">{{$t('blog.post.publish.facebook.button')}}</button>

      </div>

    </form>

</template>

<style>

</style>

<script lang="ts">
import { nextTick, watch, toRefs, reactive, Ref, ref, defineComponent, PropType, computed, onMounted } from '@fwk-node-modules/vue'
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';
import * as Ladda from 'ladda';
import Select from '@fwk-client/components/panels/input/Select.vue';

import { useFeedAdmin } from '../../../../composables/useFeedAdmin';
import { usePostAdmin } from '../../../../composables/usePostAdmin';

export default defineComponent({
  props: {
    post: Object as PropType<any>
  },
  components: {
      Select
  },
  setup(props, context) {
    const app = getApp();
    const $router = useRouter();
    const $store = useStore();

    const { selectedFeed } = useFeedAdmin(props, context);
    const { publishPostFacebook, getFacebookPostDetails, publishFacebookLive:postPublishFacebookLive, removePostFromFacebook:postRemovePostFromFacebook } = usePostAdmin(props, context);

    const { post } = toRefs(props);

    var form:any = reactive({
      pageID : null
    })

    const listPages:Ref<any[]> = ref([]);
    const listEligiblePages:Ref<any[]> = ref([]);
    const facebookPostDetails:Ref<any> = ref({});

    var laddaSubmit:Ladda.LaddaButton|null = null;

    onMounted(() => {
      
    })

    const facebookPosts = computed(() => {
      return post.value.socials && post.value.socials.facebook && post.value.socials.facebook.posts ? post.value.socials.facebook.posts : []
    })

    const pageFromId = computed(() => {
      return (pageID:string) => {
        var indexPage = listPages.value.map((page) => page.id).indexOf(pageID);
        if(indexPage > -1) { return listPages.value[indexPage] }
        return undefined;
      }
    })

    const getPageNameFromPost = (facebookPost:any) => {
      var page = pageFromId.value(facebookPost.pageID);
      if(page) { return page.name; }
      return facebookPost.pageID;
    }

    const checkLaddaButton = () => {
      var submitButton:HTMLButtonElement|null = document.querySelector( 'form[name=publish-post-facebook] button.publish' );
      laddaSubmit = Ladda.create(submitButton!);
    }

    const updateListPages = () => {
      if(selectedFeed && selectedFeed.value.feed.socials && selectedFeed.value.feed.socials.facebook && selectedFeed.value.feed.socials.facebook.pages) {  
        var pages = selectedFeed.value.feed.socials.facebook.pages;
        var facebookPostsPageIds = facebookPosts.value.map((facebookPost:any) => facebookPost.pageID)
        listEligiblePages.value = pages.filter((page:any) => {
          return facebookPostsPageIds.indexOf(page.id) < 0
        })
        listPages.value = pages;
        return;
      }
      else {
        listEligiblePages.value = [];
        listPages.value = [];
      }
    }
    updateListPages();
    

    const checkFacebookPostDetails = () => {
      for(var facebookPost of facebookPosts.value) {
        getFacebookPostDetails(post.value._id, facebookPost.pageID).then((facebookPost:any) => {
          if(facebookPost) {  
            facebookPostDetails.value[facebookPost.id] = facebookPost;
          }
        })
      }
    }
    checkFacebookPostDetails();

    const publishFacebookLive = (facebookPost:any) => {
      postPublishFacebookLive(post.value._id, facebookPost.pageID).then((published:boolean) => {
        if(published) {  
          checkFacebookPostDetails();
        }
      })
    }

    const removeFromFacebook = (facebookPost:any) => {
      postRemovePostFromFacebook(post.value._id, facebookPost.pageID);
    }

    const onFormSubmit = (evt:Event) => {
      // We prevent submit of the page
      evt.preventDefault();

      laddaSubmit!.start();
      publishPostFacebook(post.value._id, form.pageID).then((result:any) => {
        if(result.published) {  
          var post = result.post;
          // We emit event as user is updated
          context.emit('post-published', post);

          // We update the form based on values returned by backend (in case not updated)
          form.pageID = null;
        }

        laddaSubmit!.stop();
      });
    }

    watch(
      post,
      (val:any, oldVal:any) => {
        checkFacebookPostDetails();
      },
      { deep: true }
    )

    watch(
      listEligiblePages,
      (val:any, oldVal:any) => {
        if(listEligiblePages.value.length > 0) {
          nextTick(() => {
            checkLaddaButton();
          })
        }
      }
    )

    return {
      form,
      onFormSubmit,
      facebookPosts,
      facebookPostDetails,
      getPageNameFromPost,
      publishFacebookLive,
      removeFromFacebook,
      listEligiblePages,

    }

  }
});
</script>