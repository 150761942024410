<template>
  <div class="ibox posts">
    <div class="ibox-title">
      <h2>{{ $t('blog.post.list.title') }}</h2>
    </div>
    <div :class="'ibox-content p-md' + (listLoading ? ' sk-loading' : '')">

      <div v-if="listLoading" class="sk-spinner sk-spinner-double-bounce">
          <div class="sk-double-bounce1"></div>
          <div class="sk-double-bounce2"></div>
      </div>

      <p v-if="showNumberOfRows">Number of rows: {{numberOfRows}}</p>
    
      <b-table 
          outlined striped
          :items="posts"
          :fields="listFields"
          ref="listItems">
        <template v-slot:cell(select)="row">
          <p-check class="p-default p-curve p-primary" color="success" :value="row.item._id" v-model="listItemsSelected"></p-check>
        </template>
        <template v-slot:cell(web)="row">
          <a v-if="row.item.web" :href="row.item.web" target="_blank">{{row.item.web}}</a>
        </template>
        <template v-slot:cell(options)="row">
          <span v-if="isPostUpdateAllowed">
            <a href="javascript:void(0)" @click="showUpdateModal(row.item)">{{$t('blog.post.list.options.update')}}</a>
            /
          </span>
          <span v-if="isPostCopyAllowed">
            <a href="javascript:void(0)" @click="showCopyModal(row.item)">{{$t('blog.post.list.options.copy')}}</a>
            /
          </span>
          <span v-if="isPostPublishAllowed && selectedFeed.productionFeed != null">
            <a href="javascript:void(0)" @click="showPublishModal(row.item)">{{$t('blog.post.list.options.publish')}}</a>
            /
          </span>
          <span v-if="isPostRemoveAllowed">
            <a href="javascript:void(0)" @click="confirmRemoveItem(row.item)">{{$t('blog.post.list.options.delete')}}</a>
          </span>
        </template>
      </b-table>

      <button v-if="isPostCreateAllowed" class="btn btn-primary" @click="showCreateItemModal()">{{$t('blog.post.list.create-button')}}</button>

      <b-modal size="xl" ref="updateItemModal" :no-enforce-focus="true" :title="$t('blog.post.update.title', {postTitle: getLocalizedText(itemToUpdate.title)})" hide-footer lazy>
        <Update :post="itemToUpdate" :postStaticsDomain="postStaticsDomain" />
      </b-modal>

      <b-modal size="xl" ref="createItemModal" :no-enforce-focus="true" :title="$t('blog.post.create.title')" hide-footer lazy>
        <Create v-on:post-created="onItemCreated" />
      </b-modal>

      <b-modal size="xl" ref="copyItemModal" :no-enforce-focus="true" :title="$t('blog.post.copy.title', {postTitle: getLocalizedText(itemToUpdate.title)})" hide-footer lazy>
        <Copy :post="itemToUpdate" />
      </b-modal>

      <b-modal size="xl" ref="publishItemModal" :no-enforce-focus="true" :title="$t('blog.post.publish.title', {postTitle: getLocalizedText(itemToUpdate.title)})" hide-footer lazy>
        <Publish :post="itemToUpdate" v-on:post-published="onItemPublished" />
      </b-modal>

      <b-modal ref="removeItemModal" 
          hide-header
          @ok="removeItem">
        {{$t('blog.post.list.delete-confirmation', {postTitle: getLocalizedText(itemToRemove.title)})}}
      </b-modal>
    </div>
  </div>
</template>

<style scoped>
  
</style>



<script lang="ts">
import Vue from '@fwk-node-modules/vue';
import { Component, Watch, Prop } from '@fwk-node-modules/vue-property-decorator';
import Update from './Update.vue';
import Create from './Create.vue';
import Publish from './Publish.vue';
import Copy from '../admin/posts/Copy.vue';
import { languagesTypes } from '@fwk-client/store/types';
import { formatDate } from '@igotweb-node-api-utils/formatter';

import { useFeedAdmin } from '../../../composables/useFeedAdmin';
import { usePostAdmin } from '../../../composables/usePostAdmin';

@Component({
  components: { 
    Create,
    Update,
    Copy,
    Publish
  }
})
export default class ListPosts extends Vue {

  setup(props:any, context:any) {
    const { feeds, selectedFeed, selectFeed } = useFeedAdmin(props, context);
    const { posts, removePost, isPostUpdateAllowed,
    isPostCreateAllowed,
    isPostRemoveAllowed,
    isPostCopyAllowed,
    isPostPublishAllowed, postStaticsDomain } = usePostAdmin(props, context);
    return { feeds, selectedFeed, posts, removePost, selectFeed, isPostUpdateAllowed,
    isPostCreateAllowed,
    isPostRemoveAllowed,
    isPostCopyAllowed,
    isPostPublishAllowed, postStaticsDomain }
  }

  @Prop({
    type: String,
    required: false
  }) readonly path!: string | undefined

  isMultiSelectAllowed = false;
  showNumberOfRows = false;


  get numberOfRows():number {
    return this.feeds.value.length;
  };

  itemToRemove:any = {};
  itemToUpdate:any = {};
  itemToUpdateProd:any = {};

  listLoading:boolean = false;
  listItemsSelected = [];

  listFields = [
      {
        key: "select",
        label: "",
        class: (this.isMultiSelectAllowed ? "" : "hidden"),
      },
      {
        key: "title",
        label: "",
        formatter: (value:any, key:any, item:any) => {
          return this.getLocalizedText(item.title);
        }
      },
      {
        key: "lastUpdateDateTime",
        label: "",
        formatter: (value:Date, key:any, item:any) => {
          return formatDate(value, this.currentLanguageCode);
        }
      },
      {
        key: "shortDescription",
        label: "",
        formatter: (value:any, key:any, item:any) => {
          return this.getLocalizedText(item.shortDescription);
        }
      },
      {
        key: "options"
      }
    ];

  mounted() {
    this.listFields[1].label = this.$t('blog.post.list.headers.title') as string;
    this.listFields[2].label = this.$t('blog.post.list.headers.lastUpdateDateTime') as string;
    this.listFields[3].label = this.$t('blog.post.list.headers.shortDescription') as string;
    this.listFields[4].label = this.$t('blog.post.list.headers.options') as string;
  }

  get currentLanguageCode() {
    return this.$store.getters['languages/' + languagesTypes.getters.GET_CURRENT_LANGUAGE]
  }

  onItemCreated() {
    // @ts-ignore
    this.$refs.createItemModal.hide()
  }

  onItemPublished(item:any) {
    this.itemToUpdate = item;
    // @ts-ignore
    this.$refs.listItems.refresh()
  }
  
  confirmRemoveItem(item:any) {
    this.itemToRemove = item;
    // @ts-ignore
    this.$refs.removeItemModal.show()
  }

  showUpdateModal(item:any) {
    this.itemToUpdate = item;
    // @ts-ignore
    this.$refs.updateItemModal.show()
  }

  showCreateItemModal() {
    // @ts-ignore
    this.$refs.createItemModal.show()
  }

  showCopyModal(item:any) {
    this.itemToUpdate = item;
    // @ts-ignore
    this.$refs.copyItemModal.show()
  }

  showPublishModal(item:any) {
    this.itemToUpdate = item;
    // @ts-ignore
    this.$refs.publishItemModal.show()
  }

  removeItem() {
    this.listLoading = true;
    this.removePost(this.itemToRemove._id).then((response:any) => {
      // We reset the user to remove
      this.itemToRemove = {};

      this.listLoading = false;
    });
  }
}
</script>