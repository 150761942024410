import { Ref, watch, computed, ref, reactive, defineProps, PropType } from "@fwk-node-modules/vue";
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';
import { authenticationTypes } from '@fwk-client/store/types';
import { types as applicationTypes } from '@root/src/client/store/application';
import {generateBase64SrcFromFormFile} from '@fwk-client/utils/browser';
import * as api from '@fwk-client/utils/api';

import { useBlogAdmin } from "./useBlogAdmin";
import { useFeedAdmin } from "./useFeedAdmin";
import { post } from "jquery";

interface PostAdminInput {
}

export function usePostAdmin(props:PostAdminInput, context:any) { 
  const app = getApp();
  const store = useStore();

  const { callBlogAdmin } = useBlogAdmin(props, context);
  const { selectedFeed } = useFeedAdmin(props, context);
  const hasUserRole = store.getters['authentication/' + authenticationTypes.getters.HAS_USER_ROLE];

  var isListLoading:Ref<boolean> = ref(false);

  // We watch selected feed is updated
  watch(
    () => selectedFeed.value.feed._id,
    (val:any, oldVal:any) => {
      updateListPosts();
    },
    { deep: false }
  )

  const posts = computed(() => {
    return store.getters['application/'+applicationTypes.getters.GET_USER_POSTS];
  })

  const getListPosts = async (feedID:string, targetDB?:string) => {
    var result = {
      posts: [],
      options: {} as any,
      staticsDomain:undefined
    }
    var input = {
      feedID,
      targetDB
    };

    try {
      var response = await callBlogAdmin('/posts/list', input);
      if(response.posts) {
        result.posts = response.posts,
        result.options = response.options,
        result.staticsDomain = response.staticsDomain
      }
    }
    catch(error:any) {
      console.log(error);
    }
    return result;
  }

  const updateListPosts = async () => {
    if(!selectedFeed) {
      store.commit('application/'+applicationTypes.mutations.SET_USER_POSTS,[]);
    }

    isListLoading.value = true;

    try {
      var response = await getListPosts(selectedFeed.value.feed._id)
      store.commit('application/'+applicationTypes.mutations.SET_USER_POSTS, response.posts);
      if(response.options) {
        isPostUpdateAllowed.value = response.options.isUpdateAllowed;
        isPostCreateAllowed.value = response.options.isCreateAllowed;
        isPostRemoveAllowed.value = response.options.isRemoveAllowed;
        isPostCopyAllowed.value = response.options.isCopyAllowed;
        isPostPublishAllowed.value = response.options.isPublishAllowed;
      }
      if(response.staticsDomain) {
        postStaticsDomain.value = response.staticsDomain
      }
      else {
        postStaticsDomain.value = undefined;
      }
    }
    catch(error:any) {
      console.log(error);
    }
    isListLoading.value = false;
  }
  updateListPosts();

  const isPostUpdateAllowed:Ref<boolean> = ref(false);
  const isPostCreateAllowed:Ref<boolean> = ref(false);
  const isPostRemoveAllowed:Ref<boolean> = ref(false);
  const isPostCopyAllowed:Ref<boolean> = ref(false);
  const isPostPublishAllowed:Ref<boolean> = ref(false);

  const postStaticsDomain:Ref<string|undefined> = ref(undefined);

  var postForm:any = reactive({
    postID: undefined,
    title: null,
    shortDescription: null,
    localizedText: {},
    picture: null,
    pictures: [],
    loggedUsers: false
  })

  const updatePostFormWithPost = (post:any) => {
    postForm.postID = post._id;
    postForm.title = post.title;
    postForm.shortDescription = post.shortDescription;
    postForm.localizedText = post.content ? post.content.localizedText : {};
    postForm.picture = null;
    postForm.pictures = [];
    postForm.loggedUsers = post.authRoles && post.authRoles.length > 0;
  }

  const createPost = async () => {
    // We first clear all base64 pictures to reduce size of the localizedText
    var localizedText = clearBase64Pictures(postForm.localizedText);
    // We generate the form data with 
    var formData = await generateFormDataAndUpdateContent(localizedText);

    var input = {
      ...postForm,
      localizedText: localizedText,
      "feedID" : selectedFeed.value.feed._id,
    }

    delete input.pictures;
    delete input.picture;

    try {
      var response = await callBlogAdmin('/posts/create', input, formData);
      if(response.created) {  
        // We update the list of posts
        updateListPosts();

        // We reset the form
        postForm.title = "";
        postForm.shortDescription = "";
        postForm.localizedText = {};
        postForm.picture = null;
        postForm.pictures = [];
        postForm.loggedUsers = false;
        return true;
      }
    }
    catch(error:any) {
      console.log(error);
    }
    return false;
  }

  const checkExistingPicture = async (formPicture:File, formPicturesToUpload:{picture:File}[]) => {
    var existingIndex = -1;
    for(var index = 0 ; index < formPicturesToUpload.length ; index++) {
      var formPictureToUpload = formPicturesToUpload[index];
      var sameName = formPictureToUpload.picture.name == formPicture.name;
      if(sameName) {
        // We need to check on the base 64 version
        var formPictureToUploadBase64 = await generateBase64SrcFromFormFile(formPictureToUpload.picture);
        var formPictureBase64 = await generateBase64SrcFromFormFile(formPicture);
        var sameBase64 = formPictureToUploadBase64 == formPictureBase64;
        if(sameBase64) {
          existingIndex = index;
          break;
        }
      }
    }
    return existingIndex;
  }

  const clearBase64Pictures = (localizedText:any) => {
    var updatedContent:any = {};
    for(var languageCode of Object.keys(localizedText)) {
      var localizedContent = localizedText[languageCode];
      updatedContent[languageCode] = localizedContent.replaceAll(/src=\"data:[^\"]*base64[^\"]*\"/ig, "src=\"\"");
    }
    return updatedContent;
  }

  const getPicturesToUpload = (localizedText:any) => {
    var picturesToUpload:Set<string> = new Set();
    for(var languageCode of Object.keys(localizedText)) {
      var localizedContent = localizedText[languageCode];
      var matches = [...localizedContent.matchAll(/upload-picture-id=\"([^\"]*)\"/ig)];
      if(matches.length > 0) {
        matches.forEach((match:any) => {
          picturesToUpload.add(match[1])
        })
      }
    }
    return [...picturesToUpload];
  }

  const updateContentWithNewPictureIDs = (localizedText:any, formPicturesToUpload:{initialIDs:string[]}[]) => {
    var updatedContent:any = {};
    for(var languageCode of Object.keys(localizedText)) {
      for(var newIndex = 0 ; newIndex < formPicturesToUpload.length ; newIndex++) {
        // We want to replace the initial IDs with the new index
        var initialIDS = formPicturesToUpload[newIndex].initialIDs;
        for(var initialID of initialIDS) {
          var localizedContent = updatedContent[languageCode] ? updatedContent[languageCode] : localizedText[languageCode];
          // We replace it in the localizedText with newIndex
          var searchRegExp = new RegExp('upload-picture-id="'+initialID+'"', 'ig'); 
          updatedContent[languageCode] = localizedContent.replaceAll(searchRegExp, "upload-picture-id=\""+newIndex+"\"")
        }
      }
    }
    return updatedContent;
  }

  const generateFormDataAndUpdateContent = async (localizedText:any) => {
    var formData = new FormData();
    if(postForm.pictures && postForm.pictures.length > 0) {
      // We get the uploaded pictures from the localizedText
      var picturesToUploadIndexes = getPicturesToUpload(localizedText);
      var formPicturesToUpload:{picture:File, initialIDs:string[]}[] = [];

      for(var pictureToUploadIndex of picturesToUploadIndexes) {
        // We get the associated form picture
        var formPicture = postForm.pictures[parseInt(pictureToUploadIndex)];
        // We check if not already added in the list of pictures to upload
        var existingIndex = await checkExistingPicture(formPicture, formPicturesToUpload);
        if(existingIndex > -1) {
          // The picture is already in the list so we add the initial ID
          formPicturesToUpload[existingIndex].initialIDs.push(pictureToUploadIndex)
        }
        else {
          // The picture is not yet in the list
          formPicturesToUpload.push({
            picture: formPicture,
            initialIDs: [pictureToUploadIndex]
          })
        }
      }

      // We need to replace in the localizedText the initial ID with the new index of the uploaded files
      localizedText = updateContentWithNewPictureIDs(localizedText, formPicturesToUpload);

      for(var formPictureToUpload of formPicturesToUpload) {
        // We check if the picture is within the localizedText
        formData.append("pictures", formPictureToUpload.picture, formPictureToUpload.picture.name);  
      }
    }
    if(postForm.picture) {
      formData.append("picture", postForm.picture, postForm.picture.name);
    }
    return formData;
  }

  const updatePost = async () => {
    var result:any = {
      updated:false
    }

    if(!postForm.postID) {
      return result;
    }

    // We first clear all base64 pictures to reduce size of the localizedText
    var localizedText = clearBase64Pictures(postForm.localizedText);
    // We generate the form data with 
    var formData = await generateFormDataAndUpdateContent(localizedText);

    var input = {
      ...postForm,
      localizedText: localizedText
    }

    delete input.pictures;

    isListLoading.value = true;

    try {
      var response = await callBlogAdmin('/post/'+postForm.postID+'/update', input, formData);
      if(response.updated) {  
        // We update the list of posts
        updateListPosts();

        var post = response.post;
        // We update the form based on values returned by backend (in case not updated)
        updatePostFormWithPost(post);

        // We update the result
        result.updated = true;
        result.post = post;
      }
    }
    catch(error:any) {
      console.log(error);
    }
    isListLoading.value = false;
    return result;
  }

  /*
  const uploadPicture = async (postID:string, picture:File, apiOptions?:api.ApiOptions):Promise<{uploaded:boolean, picture?:any}> => {

    var input = {};
    var formData = new FormData();
    if(picture != null) {
      formData.append("upload", picture, picture.name);  
    }

    var result:{uploaded:boolean, picture?:any} = {
      uploaded:false
    }

    try {
      var response = await callBlogAdmin('/post/'+postID+'/picture/upload', input, formData, apiOptions);
      if(response.uploaded) {
        result.uploaded = true;
        result.picture = response.picture;
      }
    }
    catch(error:any) {
      console.log(error);
    }
    return result;
  }
  */
  const removePicture = async (postID:string) => {

    var result:any = {
      removed:false
    }

    try {
      var response = await callBlogAdmin('/post/'+postID+'/picture/remove');
      if(response.removed) {
        result.removed = true;
        result.post = response.post;
      }
    }
    catch(error:any) {
      console.log(error);
    }
    return result;
  }

  const removePost = async (postID:string) => {

    isListLoading.value = true;

    try {
      var response = await callBlogAdmin('/post/'+postID+'/remove');
      if(response.removed) {
        updateListPosts();
      }
    }
    catch(error:any) {
      console.log(error);
    }
    isListLoading.value = false;
    return true;
  }

  const publishPostProduction = async (postID:string) => {
    var result:any = {
      published: false
    }

    try {
      var response = await callBlogAdmin('/post/'+postID+'/deployment/publish');
      if(response.published) {  
        result.published = true;
        result.post = response.post;
      }
    }
    catch(error:any) {
      console.log(error);
    }
    return result;
  }

  const publishPostSocial= async (postID:string, pageID:string, social:"facebook"|"linkedin") => {
    var input:any = {
      pageID: pageID,
      postID: postID,
    }

    var result:any = {
      published: false
    }

    try {
      var response = await callBlogAdmin('/post/'+postID+'/deployment/publish-'+social, input);
      if(response.published) {  
        result.published = true;
        result.post = response.post;
      }
    }
    catch(error:any) {
      console.log(error);
    }
    return result;
  }

  const publishPostFacebook = async (postID:string, pageID:string) => {
    return publishPostSocial(postID, pageID, "facebook");
  }

  const publishPostLinkedIn = async (postID:string, pageID:string) => {
    return publishPostSocial(postID, pageID, "linkedin");
  }

  const getProductionPostDetails = async (postID:string) => {

    try {
      var response = await callBlogAdmin('/post/'+postID+'/deployment/post');
      if(response.post) {  
        return response.post;
      }
    }
    catch(error:any) {
      console.log(error);
    }
    return null;
  }

  const getFacebookPostDetails = async (postID:string, pageID:string) => {
    var input:any = {
      postID: postID,
      pageID: pageID,
    }

    try {
      var response = await callBlogAdmin('/post/'+postID+'/deployment/post-facebook', input);
      if(response.facebookPost) {  
        return response.facebookPost;
      }
    }
    catch(error:any) {
      console.log(error);
    }
    return null;
  }

  const getLinkedInPostDetails = async (postID:string, pageID:string) => {
    var input:any = {
      postID: postID,
      pageID: pageID,
    }

    try {
      var response = await callBlogAdmin('/post/'+postID+'/deployment/post-linkedin', input);
      if(response.linkedInPost) {  
        return response.linkedInPost;
      }
    }
    catch(error:any) {
      console.log(error);
    }
    return null;
  }

  const publishFacebookLive = async (postID:string, pageID:string) => {
    var input:any = {
      postID: postID,
      pageID: pageID,
    }

    try {
      var response = await callBlogAdmin('/post/'+postID+'/deployment/publish-facebook-live', input);
      if(response.published) {  
        return true;
      }
    }
    catch(error:any) {
      console.log(error);
    }
    return false;
  }

  const removePostFromSocial = async (postID:string, pageID:string, social:"facebook"|"linkedin") => {
    var input:any = {
      postID: postID,
      pageID: pageID
    }

    var result:any = {
      removed:false
    }

    try {
      var response = await callBlogAdmin('/post/'+postID+'/deployment/remove-'+social, input);
      if(response.removed) {  
        // We update the list of posts
        updateListPosts();

        result.removed = true;
        result.post = response.post;
      }
    }
    catch(error:any) {
      console.log(error);
    }
    return result;
  }

  const removePostFromFacebook = async (postID:string, pageID:string) => {
    return removePostFromSocial(postID, pageID, "facebook")
  }

  const removePostFromLinkedIn = async (postID:string, pageID:string) => {
    return removePostFromSocial(postID, pageID, "linkedin")
  }

  return {
    isPostUpdateAllowed,
    isPostCreateAllowed,
    isPostRemoveAllowed,
    isPostCopyAllowed,
    isPostPublishAllowed,
    postStaticsDomain,
    // uploadPicture,
    removePicture,
    removePost,
    posts,
    getListPosts,
    postForm,
    createPost,
    updatePostFormWithPost,
    updatePost,
    publishPostFacebook,
    getFacebookPostDetails,
    publishFacebookLive,
    removePostFromFacebook,
    publishPostLinkedIn,
    getLinkedInPostDetails,
    removePostFromLinkedIn,
    getProductionPostDetails,
    publishPostProduction
  }
  
}