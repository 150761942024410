<template>
  
    <form role="form" @submit="onFormSubmit" name="update-post" class="update-post">
      <TextField 
        v-bind:value="post.path" 
        id="path" 
        :label="$t('blog.post.info.path')" 
        :placeholder="$t('blog.post.info.path_placeholder')" 
        required 
        disabled
        labelAsColumn 
      />
      <SwitchCheck 
          v-bind:value.sync="postForm.loggedUsers" 
          id="loggedUsers" 
          :label="$t('blog.post.info.loggedUsers')" 
          :labelAsColumn="true"
      />
      <LocalizedText 
        v-bind:value.sync="postForm.title" 
        id="title" 
        :label="$t('blog.post.info.title')" 
        :placeholder="$t('blog.post.info.title_placeholder')" 
        :languageCodes="availableLanguageCodes" 
        required 
        labelAsColumn 
      />
      <LocalizedText 
        v-bind:value.sync="postForm.shortDescription" 
        id="shortDescription" 
        :label="$t('blog.post.info.shortDescription')" 
        :placeholder="$t('blog.post.info.shortDescription_placeholder')" 
        :languageCodes="availableLanguageCodes" 
        required 
        labelAsColumn 
      />
      <Picture 
        v-bind:value.sync="postForm.picture" 
        :picture="picture" 
        :labels="{
          label: labels.picture
        }"
      >
        <template v-slot:buttons>
          <button class="btn btn-primary ladda-button removePicture" :disabled="postForm.picture != undefined" data-style="zoom-in" type="button" @click="onRemovePicture">{{ getLocalizedText(labels.removePictureButton) }}</button>
        </template>
      </Picture>
      <ListPictures 
        v-if="showListPictures"
        v-bind:listPictures.sync="pictures"
        :options="getListPicturesOptions()"
        id="listPictures" 
        :label="$t('blog.post.info.pictures')" 
        labelAsColumn 
      />
      <LocalizedContent 
        v-bind:value.sync="postForm.localizedText" 
        id="localizedText" 
        :label="$t('blog.post.info.content')" 
        :placeholder="$t('blog.post.info.content_placeholder')" 
        :languageCodes="availableLanguageCodes" 
        :options="getLocalizedContentOptions()" 
        required 
        labelAsColumn 
      />
      <button class="btn btn-primary ladda-button update" data-style="zoom-in" type="submit">{{$t('blog.post.update.button')}}</button>

    </form>

</template>

<style>

  /* We ensure heading has proper sizing */
  /* h2 is to be used for intro description for blog post */
  .update-post .ck-content h2 {
    font-size: 15px;
    font-weight: 400;
  }
  .update-post .ck-content h3 {
    font-size: 14px;
    font-weight: 200;
  }
  .update-post .ck-content h4 {
    font-size: 13px;
    font-weight: 200;
  }

</style>

<script lang="ts">
import { toRefs, Ref, defineComponent, PropType, computed, onMounted, onBeforeUnmount, ref, watch, reactive } from '@fwk-node-modules/vue'
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';

import TextField from '@fwk-client/components/panels/input/TextField.vue';
import LocalizedText from '@fwk-client/components/panels/input/LocalizedText.vue';
import LocalizedContent from '@root/src/client/components/panels/input/LocalizedContent.vue';
import ListPictures from '@root/src/client/components/panels/input/ListPictures.vue';
import SwitchCheck from '@fwk-client/components/panels/input/SwitchCheck.vue';
import Picture from '@fwk-client/components/panels/input/Picture.vue'

import * as Ladda from 'ladda';
import { authenticationTypes } from '@fwk-client/store/types';
import { roles as apiRoles } from '@igotweb/core-api/src/roles';

import {generateBase64SrcFromFormFile} from '@fwk-client/utils/browser';

import { usePostAdmin } from '../../../composables/usePostAdmin';
import { LocalizedContentOptions } from '@root/src/client/components/panels/input/content/interfaces';

export default defineComponent({
  props: {
      post: {
        type: Object,
        required: true
      },
      postStaticsDomain: {
        type: String,
        required: true
      }
  },
  components: {
    TextField,
    LocalizedText,
    LocalizedContent,
    ListPictures,
    SwitchCheck,
    Picture
  },
  setup(props, context) {
    const app = getApp();
    const $router = useRouter();
    const $store = useStore();

    const { updatePost, postForm, updatePostFormWithPost, removePicture } = usePostAdmin(props, context);

    const { post, postStaticsDomain } = toRefs(props);

    const availableLanguageCodes = ['fr','en'];

    const pictures = ref(post.value.pictures);
    const picture = ref(post.value.picture);

    const labels = {
      "picture" : {
        "fr" : "Image principale",
        "en" : "Main picture"
      },
      "removePictureButton" : {
        "fr" : "Supprimer l'image",
        "en" : "Remove picture"
      }
    }

    var laddaSubmit:Ladda.LaddaButton|null = null;
    var laddaRemovePicture:Ladda.LaddaButton|null = null;

    updatePostFormWithPost(post.value);
  
    onMounted(() => {
      var submitButton:HTMLButtonElement|null = document.querySelector( 'form[name=update-post] button.update' );
      laddaSubmit = Ladda.create(submitButton!);
      var removePictureButton:HTMLButtonElement|null = document.querySelector( 'form[name=update-post] button.removePicture' );
      laddaRemovePicture = Ladda.create(removePictureButton!);
    })

    const onFormSubmit = (evt:Event) => {
      // We prevent submit of the page
      evt.preventDefault();

      laddaSubmit!.start();
      updatePost().then((result:any) => {
        if(result.updated) {  
          var post = result.post;
          pictures.value = post.content ? post.content.pictures : [];
          picture.value = post.picture;
          // We emit event as user is updated
          context.emit('post-updated', post);
        }
        laddaSubmit!.stop();
      })
    }

    const showListPictures:Ref<boolean> = computed(() => {
      return $store.getters['authentication/' + authenticationTypes.getters.HAS_USER_ROLE](apiRoles.superadmin);
    })

    const getLocalizedContentOptions = () => {
      var allowSourceEditing = $store.getters['authentication/' + authenticationTypes.getters.HAS_USER_ROLE](apiRoles.superadmin);
      var options = {
        upload : (picture:File) => {
          return generateBase64SrcFromFormFile(picture).then((src:string) => {
            // We add the picture in the form
            postForm.pictures.push(picture);
            // We return the base64 picture for the localizedText
            return {
              uploaded: true,
              uploadPictureID: postForm.pictures.length-1, // We provide an ID to ensure we can associate the picture in the form
              picture: {
                original : {
                  publicUrl: src
                }
              }
            }
          })
        },
        allowSourceEditing : allowSourceEditing,
        // editor:'tinymce',
        style: 'h2 { font-size: 15px; font-weight: 400; } h3 { font-size: 14px; font-weight: 200; } h4 { font-size: 13px; font-weight: 200; }',
        staticsDomain: postStaticsDomain.value
      } as LocalizedContentOptions
      return options;
    }

    const getListPicturesOptions = () => {
      var options = {
        upload : undefined, 
        remove : undefined,
      }
      return options;
    } 
    
    const onRemovePicture = (evt:Event) => {
      // We prevent submit of the page
      evt.preventDefault();

      laddaRemovePicture!.start();
      removePicture(post.value._id).then((result:any) => {
        if(result.removed) {  
          var post = result.post;
          picture.value = post.picture;
          // We emit event as user is updated
          context.emit('post-updated', post);
        }
        laddaRemovePicture!.stop();
      })
    }

    return {
      post,
      onFormSubmit,
      postForm,
      pictures,
      picture,
      availableLanguageCodes,
      getListPicturesOptions,
      getLocalizedContentOptions,
      showListPictures,
      labels,
      onRemovePicture
    }
  }
})
</script>