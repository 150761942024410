<template>
  
    <form role="form" @submit="onFormSubmit" name="create-post">
      <SwitchCheck 
          v-bind:value.sync="postForm.loggedUsers" 
          id="loggedUsers" 
          :label="$t('blog.post.info.loggedUsers')" 
          :labelAsColumn="true"
      />
      <LocalizedText 
        v-bind:value.sync="postForm.title" 
        id="title" 
        :label="$t('blog.post.info.title')" 
        :placeholder="$t('blog.post.info.title_placeholder')" 
        :languageCodes="availableLanguageCodes" 
        required labelAsColumn 
      />
      <LocalizedText 
        v-bind:value.sync="postForm.shortDescription" 
        id="shortDescription" 
        :label="$t('blog.post.info.shortDescription')" 
        :placeholder="$t('blog.post.info.shortDescription_placeholder')" 
        :languageCodes="availableLanguageCodes" 
        required labelAsColumn 
      />
      <Picture 
        v-bind:value.sync="postForm.picture" 
        :labels="{
          label: labels.picture
        }"
      />
      <!--<ListPictures 
        v-bind:value.sync="postForm.pictures"
        :options="getListPicturesOptions()"
        id="listPictures" 
        :label="$t('blog.post.info.pictures')" 
        labelAsColumn 
      />-->
      <LocalizedContent 
        v-bind:value.sync="postForm.localizedText" 
        id="localizedText" 
        :label="$t('blog.post.info.content')" 
        :placeholder="$t('blog.post.info.content_placeholder')" 
        :languageCodes="availableLanguageCodes" 
        :options="getLocalizedContentOptions()" 
        required labelAsColumn 
      />
      <button class="btn btn-primary ladda-button" data-style="zoom-in" type="submit">{{$t('blog.post.create.button')}}</button>
    </form>

</template>

<style>

  /* We ensure heading has proper sizing */
  /* h2 is to be used for intro description for blog post */
  .create-post .ck-content h2 {
    font-size: 15px;
    font-weight: 400;
  }

</style>


<script lang="ts">
import { Ref, defineComponent, PropType, computed, onMounted, onBeforeUnmount, ref, watch, reactive } from '@fwk-node-modules/vue'
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';

import LocalizedText from '@fwk-client/components/panels/input/LocalizedText.vue';
import LocalizedContent from '@root/src/client/components/panels/input/LocalizedContent.vue';
import ListPictures from '@root/src/client/components/panels/input/ListPictures.vue';
import SwitchCheck from '@fwk-client/components/panels/input/SwitchCheck.vue';
import Picture from '@fwk-client/components/panels/input/Picture.vue'

import * as Ladda from 'ladda';
import {generateBase64SrcFromFormFile} from '@fwk-client/utils/browser';
import { roles as apiRoles } from '@igotweb/core-api/src/roles';
import { authenticationTypes } from '@fwk-client/store/types';

import { usePostAdmin } from '../../../composables/usePostAdmin';
import { useFeedAdmin } from '../../../composables/useFeedAdmin';



export default defineComponent({
  props: {
      
  },
  components: {
    LocalizedText,
    LocalizedContent,
    ListPictures,
    SwitchCheck,
    Picture
  },
  setup(props, context) {
    const app = getApp();
    const $router = useRouter();
    const $store = useStore();

    const { createPost, postForm } = usePostAdmin(props, context);
    const { selectedFeed } = useFeedAdmin(props, context);

    const labels = {
      "picture" : {
        "fr" : "Image principale",
        "en" : "Main picture"
      }
    }

    const availableLanguageCodes = computed(() => {
      return selectedFeed.value.feed.availableLanguages ? selectedFeed.value.feed.availableLanguages : ["fr", "en"];
    })

    var laddaSubmit:Ladda.LaddaButton|null = null;

    onMounted(() => {
      var button:HTMLButtonElement|null = document.querySelector( 'form[name=create-post] button' );
      laddaSubmit = Ladda.create(button!);
    })

    const getLocalizedContentOptions = () => {
      var allowSourceEditing = $store.getters['authentication/' + authenticationTypes.getters.HAS_USER_ROLE](apiRoles.superadmin);
      var options = {
        upload : (picture:File) => {
          return generateBase64SrcFromFormFile(picture).then((src:string) => {
            // We add the picture in the form
            postForm.pictures.push(picture);
            // We return the base64 picture for the localizedText
            return {
              uploaded: true,
              uploadPictureID: postForm.pictures.length-1, // We provide an ID to ensure we can associate the picture in the form
              picture: {
                original : {
                  publicUrl: src
                }
              }
            }
          })
        },
        allowSourceEditing : allowSourceEditing
      }
      return options;
    }

    const getListPicturesOptions = () => {
      var options = {
        labels : {
          isInBrowser : app.$t('blog.post.create.picturesInBrowser') as string
        }
      }
      return options;
    }

    const onFormSubmit = (evt:Event) => {
      // We prevent submit of the page
      evt.preventDefault();

      laddaSubmit!.start();

      createPost().then((created:boolean) => {
        if(created) {
          context.emit('post-created');
        }
        laddaSubmit!.stop();
      });
    }

    return {
      onFormSubmit,
      getListPicturesOptions,
      getLocalizedContentOptions,
      availableLanguageCodes,
      postForm,
      labels
    }
  }
})
</script>