import { render, staticRenderFns } from "./ListPosts.vue?vue&type=template&id=5096da95&scoped=true"
import script from "./ListPosts.vue?vue&type=script&lang=ts"
export * from "./ListPosts.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5096da95",
  null
  
)

export default component.exports