var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"ibox feeds"},[_c('div',{staticClass:"ibox-title"},[_c('h2',[_vm._v(_vm._s(_vm.$t('blog.post.copy.title')))])]),_vm._v(" "),_c('div',{class:'ibox-content p-md' + (_vm.panelLoading ? ' sk-loading' : '')},[(_vm.panelLoading)?_c('div',{staticClass:"sk-spinner sk-spinner-double-bounce"},[_c('div',{staticClass:"sk-double-bounce1"}),_vm._v(" "),_c('div',{staticClass:"sk-double-bounce2"})]):_vm._e(),_vm._v(" "),_c('form',{staticClass:"copy-post",attrs:{"role":"form","name":"copy-post"},on:{"submit":_vm.onFormSubmit}},[_c('ListDBs',{attrs:{"value":_vm.form.originDB,"id":"originDB","label":"Origin DB","placeholder":"Select an origin DB","required":"","labelAsColumn":""},on:{"update:value":function($event){return _vm.$set(_vm.form, "originDB", $event)}}}),_vm._v(" "),(_vm.form.originDB)?_c('Select',{attrs:{"selectOptions":{
          options: _vm.listOriginFeeds,
          getOptionLabel : option => option.feed.name,
          reduce : option => option.feed._id
        },"value":_vm.form.originFeedID,"id":"originFeed","label":"Origin Feed","placeholder":"Select an origin feed","required":"","labelAsColumn":""},on:{"update:value":function($event){return _vm.$set(_vm.form, "originFeedID", $event)}}}):_vm._e(),_vm._v(" "),(_vm.form.originFeedID)?_c('Select',{attrs:{"selectOptions":{
          options: _vm.listOriginPosts,
          getOptionLabel : post => _vm.getLocalizedText(post.title),
          reduce : post => post._id
        },"value":_vm.form.originPostID,"id":"targetPost","label":"Origin post","placeholder":"Select an origin post","required":"","labelAsColumn":""},on:{"update:value":function($event){return _vm.$set(_vm.form, "originPostID", $event)}}}):_vm._e(),_vm._v(" "),_c('ListDBs',{attrs:{"value":_vm.form.targetDB,"id":"targetDB","label":"Target DB","placeholder":"Select a target DB","required":"","labelAsColumn":""},on:{"update:value":function($event){return _vm.$set(_vm.form, "targetDB", $event)}}}),_vm._v(" "),(_vm.form.targetDB)?_c('Select',{attrs:{"selectOptions":{
          options: _vm.listDestinationFeeds,
          getOptionLabel : option => option.feed.name,
          reduce : option => option.feed._id
        },"value":_vm.form.targetFeedID,"id":"targetFeed","label":"Target Feed","placeholder":"Select a target feed","required":"","labelAsColumn":""},on:{"update:value":function($event){return _vm.$set(_vm.form, "targetFeedID", $event)}}}):_vm._e(),_vm._v(" "),_c('button',{staticClass:"btn btn-primary ladda-button update",attrs:{"data-style":"zoom-in","type":"submit"}},[_vm._v(_vm._s(_vm.$t('blog.post.copy.button')))])],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }