<template>
  
    <form v-if="linkedInPosts.length > 0 || listEligiblePages.length > 0" role="form" @submit="onFormSubmit" name="publish-post-linkedin" class="publish-post-linkedin">
      <h2>LinkedIn</h2>
      <div class="published" v-if="linkedInPosts.length > 0">
        Published on Company pages:
        <ul>
          <li v-for="(linkedInPost,index) of linkedInPosts" :key="'linkedInPost-'+index">
            {{ getPageNameFromPost(linkedInPost) }}  - <a :href="'https://www.linkedin.com/feed/update/'+linkedInPost.activity+'/'" target="_blank" >Afficher</a>
            <button @click="removeFromLinkedIn(linkedInPost)" class="btn btn-primary remove-linkedIn" type="button">{{$t('blog.post.publish.linkedIn.remove-button')}}</button>
          </li>
        </ul>
      </div>

      <div class="publish" v-if="listEligiblePages.length > 0">

        <Select 
          :selectOptions="{
            options: listEligiblePages,
            getOptionLabel : option => option.name,
            reduce : page => page.id
          }"
          v-bind:value.sync="form.pageID" 
          id="targetPage" 
          label="LinkedIn Page" 
          placeholder="Select a LinkedIn Page" 
          required 
          labelAsColumn 
        />

        <button class="btn btn-primary ladda-button publish" data-style="zoom-in" type="submit">{{$t('blog.post.publish.linkedin.button')}}</button>

      </div>

    </form>

</template>

<style>

</style>

<script lang="ts">
import { nextTick, watch, toRefs, reactive, Ref, ref, defineComponent, PropType, computed, onMounted } from '@fwk-node-modules/vue'
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';
import * as Ladda from 'ladda';
import Select from '@fwk-client/components/panels/input/Select.vue';

import { useFeedAdmin } from '../../../../composables/useFeedAdmin';
import { usePostAdmin } from '../../../../composables/usePostAdmin';

export default defineComponent({
  props: {
    post: Object as PropType<any>
  },
  components: {
      Select
  },
  setup(props, context) {
    const app = getApp();
    const $router = useRouter();
    const $store = useStore();

    const { selectedFeed } = useFeedAdmin(props, context);
    const { publishPostLinkedIn, getLinkedInPostDetails, removePostFromLinkedIn:postRemovePostFromLinkedIn } = usePostAdmin(props, context);

    const { post } = toRefs(props);

    var form:any = reactive({
      pageID : null
    })

    const listPages:Ref<any[]> = ref([]);
    const listEligiblePages:Ref<any[]> = ref([]);
    const linkedInPostDetails:Ref<any> = ref({});

    var laddaSubmit:Ladda.LaddaButton|null = null;

    onMounted(() => {
      
    })

    const linkedInPosts = computed(() => {
      return post.value.socials && post.value.socials.linkedIn && post.value.socials.linkedIn.posts ? post.value.socials.linkedIn.posts : []
    })

    const pageFromId = computed(() => {
      return (pageID:string) => {
        var indexPage = listPages.value.map((page) => page.id).indexOf(pageID);
        if(indexPage > -1) { return listPages.value[indexPage] }
        return undefined;
      }
    })

    const getPageNameFromPost = (linkedInPost:any) => {
      var page = pageFromId.value(linkedInPost.pageID);
      if(page) { return page.name; }
      return linkedInPost.pageID;
    }

    const checkLaddaButton = () => {
      var submitButton:HTMLButtonElement|null = document.querySelector( 'form[name=publish-post-linkedin] button.publish' );
      laddaSubmit = Ladda.create(submitButton!);
    }

    const updateListPages = () => {
      if(selectedFeed && selectedFeed.value.feed.socials && selectedFeed.value.feed.socials.linkedIn && selectedFeed.value.feed.socials.linkedIn.pages) {  
        var pages = selectedFeed.value.feed.socials.linkedIn.pages;
        var linkedInPostsPageIds = linkedInPosts.value.map((linkedInPost:any) => linkedInPost.pageID)
        listEligiblePages.value = pages.filter((page:any) => {
          return linkedInPostsPageIds.indexOf(page.id) < 0
        })
        listPages.value = pages;
        return;
      }
      else {
        listEligiblePages.value = [];
        listPages.value = [];
      }
    }
    updateListPages();
    
    const checkLinkedInPostDetails = () => {
      for(var linkedInPost of linkedInPosts.value) {
        getLinkedInPostDetails(post.value._id, linkedInPost.pageID).then((linkedInPost:any) => {
          if(linkedInPost) {  
            linkedInPostDetails.value[linkedInPost.id] = linkedInPost;
          }
        })
      }
    }
    checkLinkedInPostDetails();

    const removeFromLinkedIn = (linkedInPost:any) => {
      postRemovePostFromLinkedIn(post.value._id, linkedInPost.pageID);
    }

    const onFormSubmit = (evt:Event) => {
      // We prevent submit of the page
      evt.preventDefault();

      laddaSubmit!.start();
      publishPostLinkedIn(post.value._id, form.pageID).then((result:any) => {
        if(result.published) {  
          var post = result.post;
          // We emit event as user is updated
          context.emit('post-published', post);

          // We update the form based on values returned by backend (in case not updated)
          form.pageID = null;
        }

        laddaSubmit!.stop();
      });
    }

    watch(
      post,
      (val:any, oldVal:any) => {
        checkLinkedInPostDetails();
      },
      { deep: true }
    )

    watch(
      listEligiblePages,
      (val:any, oldVal:any) => {
        if(listEligiblePages.value.length > 0) {
          nextTick(() => {
            checkLaddaButton();
          })
        }
      }
    )

    return {
      form,
      onFormSubmit,
      linkedInPosts,
      linkedInPostDetails,
      getPageNameFromPost,
      removeFromLinkedIn,
      listEligiblePages,

    }

  }
});
</script>