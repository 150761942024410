<template>
  
<div class="ibox feeds">
  <div class="ibox-title">
    <h2>{{ $t('blog.post.copy.title') }}</h2>
  </div>
  <div :class="'ibox-content p-md' + (panelLoading ? ' sk-loading' : '')">

    <div v-if="panelLoading" class="sk-spinner sk-spinner-double-bounce">
        <div class="sk-double-bounce1"></div>
        <div class="sk-double-bounce2"></div>
    </div>

    <form role="form" @submit="onFormSubmit" name="copy-post" class="copy-post">
      
      <ListDBs 
        v-bind:value.sync="form.originDB" 
        id="originDB" 
        label="Origin DB" 
        placeholder="Select an origin DB" 
        required 
        labelAsColumn 
      />

      <Select 
        v-if="form.originDB"
        :selectOptions="{
          options: listOriginFeeds,
          getOptionLabel : option => option.feed.name,
          reduce : option => option.feed._id
        }"
        v-bind:value.sync="form.originFeedID" 
        id="originFeed" 
        label="Origin Feed" 
        placeholder="Select an origin feed" 
        required 
        labelAsColumn 
      />

      <Select 
        v-if="form.originFeedID"
        :selectOptions="{
          options: listOriginPosts,
          getOptionLabel : post => getLocalizedText(post.title),
          reduce : post => post._id
        }"
        v-bind:value.sync="form.originPostID" 
        id="targetPost" 
        label="Origin post" 
        placeholder="Select an origin post" 
        required 
        labelAsColumn 
      />

      <ListDBs 
        v-bind:value.sync="form.targetDB" 
        id="targetDB" 
        label="Target DB" 
        placeholder="Select a target DB" 
        required 
        labelAsColumn 
      />

      <Select 
        v-if="form.targetDB"
        :selectOptions="{
          options: listDestinationFeeds,
          getOptionLabel : option => option.feed.name,
          reduce : option => option.feed._id
        }"
        v-bind:value.sync="form.targetFeedID" 
        id="targetFeed" 
        label="Target Feed" 
        placeholder="Select a target feed" 
        required 
        labelAsColumn 
      />

      <button class="btn btn-primary ladda-button update" data-style="zoom-in" type="submit">{{$t('blog.post.copy.button')}}</button>

    </form>
  </div>
</div>

</template>

<style>

</style>

<script lang="ts">
import * as api from '@fwk-client/utils/api';
import * as Ladda from 'ladda';
import { Ref, defineComponent, PropType, computed, onMounted, onBeforeUnmount, ref, watch, reactive } from '@fwk-node-modules/vue'
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';
import * as socket from '@fwk-client/utils/socket';
import SwitchCheck from '@fwk-client/components/panels/input/SwitchCheck.vue';
import ListDBs from '@root/src/client/components/panels/admin/input/ListDBs.vue';
import Select from '@fwk-client/components/panels/input/Select.vue';

import { useListDBs } from '@root/src/client/composables/admin/useListDBs';
import { useFeedAdmin } from '../../../../composables/useFeedAdmin';
import { usePostAdmin } from '../../../../composables/usePostAdmin';
import { useBlogAdmin } from '../../../../composables/useBlogAdmin';

export default defineComponent({
    props: {
        
    },
    components: {
      ListDBs,
      Select,
      SwitchCheck
    },
    setup(props, context) {
        const app = getApp();
        const $router = useRouter();
        const $store = useStore();
        const { listDBs, isListDBLoading, listDBsReduce, listDBsOptionLabel } = useListDBs(props, context);
        const { getListFeeds } = useFeedAdmin(props, context);
        const { getListPosts } = usePostAdmin(props, context);
        const { callBlogAdmin } = useBlogAdmin(props, context);
        

        const form:any = reactive({
          originDB:"",
          targetDB:"",
          originFeedID:"",
          originPostID:"",
          targetFeedID:""
        });

        const listOriginFeeds:Ref<any[]> = ref([])
        const listDestinationFeeds:Ref<any[]> = ref([])
        const listOriginPosts:Ref<any[]> = ref([])

        var laddaSubmit:Ladda.LaddaButton|null = null;

        onMounted(() => {
          var updateDetailsButton:HTMLButtonElement|null = document.querySelector( 'form[name=copy-post] button.ladda-button' );
          laddaSubmit = Ladda.create(updateDetailsButton!);
        })

        var formLoading:boolean = false;
        const onFormSubmit = (evt:Event) => {
          evt.preventDefault();

          var input = {
            destinationFeedID : form.targetFeedID,
            originPostID: form.originPostID,
            originDB : form.originDB,
            targetDB : form.targetDB
          }

          laddaSubmit!.start();
          formLoading = true;

          callBlogAdmin('/posts/copy', input).then((response:any) => {
            laddaSubmit!.stop();
            formLoading = false;
          }).catch((response:any) => {
            laddaSubmit!.stop();
            formLoading = false;
          })
        }

        const panelLoading = computed(() => {
            return formLoading || isListDBLoading.value;
        })

        watch(
          () => form.originDB,
          (val:any, oldVal:any) => {
            // We reset the form
            form.originFeedID = "";
            form.originPostID = "";
            // We update the list of feeds
            getListFeeds(val).then((listFeeds:any) => {
              listOriginFeeds.value = listFeeds;
            })
          },
          { deep: false }
        )

        watch(
          () => form.originFeedID,
          (val:any, oldVal:any) => {
            // We reset the form
            form.originPostID = "";
            // We update the list of posts
            getListPosts(val, form.originDB).then((result:any) => {
              listOriginPosts.value = result.posts;
            })
          },
          { deep: false }
        )

        watch(
          () => form.targetDB,
          (val:any, oldVal:any) => {
            // We reset the form
            form.targetFeedID = "";
            // We update the list of feeds
            getListFeeds(val).then((listFeeds:any) => {
              listDestinationFeeds.value = listFeeds;
            })
          },
          { deep: false }
        )

        return {
          panelLoading,
          listOriginFeeds,
          listDestinationFeeds,
          listOriginPosts,
          listDBsOptionLabel,
          listDBsReduce,
          onFormSubmit,
          listDBs,
          form
        }
  }
})
</script>